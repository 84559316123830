
import welcome from "../assets/events/welcome.png";
import closed from "../assets/events/ApplicationsClosed.png";
import open from "../assets/events/Applicationsopen.png";
import bonfire from "../assets/events/BonfireSocial.png";
import retreat from "../assets/events/CohortRetreat.png";
import game from "../assets/events/GameNight.png";
import gbm1 from "../assets/events/GBM1.png";
import gbm2 from "../assets/events/GBM2.png";
import gbm3 from "../assets/events/GBM3.png";
import gbm4 from "../assets/events/GBM4.png";
import gbm5 from "../assets/events/GBM5.png";
import gbm6 from "../assets/events/GBM6.png";
import gbm7 from "../assets/events/GBM7.png";
import gbm8 from "../assets/events/GBM8.png";
import gbm9 from "../assets/events/GBM9.png";
import gbm10 from "../assets/events/GBM10.png";
import gbm11 from "../assets/events/GBM11.png";
import holiday from "../assets/events/HolidaySocial.png";
import reveal from "../assets/events/MentorReveal.png";
import mixer from "../assets/events/MocktailMixer.png";
import prog1 from "../assets/events/Programming1.png";
import prog2 from "../assets/events/Programming2.png";
import prog3 from "../assets/events/Programming3.png";
import prog4 from "../assets/events/Programming4.png";
import prog5 from "../assets/events/Programming5.png";
import downtown from "../assets/events/ProjectDowntown.png";
import pumpkin from "../assets/events/PumpkinSocial.png";
import repurpose from "../assets/events/RepurposeProject.png";
import scavenger2 from "../assets/events/ScavengerHunt2.png";
import scavenger1 from "../assets/events/ScavengerHunt.png";
import speeddating from "../assets/events/SpeedDating.png";
import study from "../assets/events/StudySocial.png";
import showcase from "../assets/events/SuccessShowcase.png";
import thanksgiving from "../assets/events/ThanksgivingPotluck.png";
import winterformal from "../assets/events/WinterFormal.png";



const events = [

  {
    name: "Cohort Applications Open!",
    info: "Mon, Aug 19, 2024",
    desc:
      "Apply to be part of the FGLP family!",
    image: open,
  },
  {
    name: "Welcome Baby Gators!",
    info: "Thu, Aug 22, 2024",
    image: welcome,
  },
  {
    name: "Cohort Applications Close",
    info: "Sat, Aug 31, 2024",
    desc:
      "Apply to be part of the FGLP family!",
    image: closed,
  },
  {
    name: "Study Social",
    info: "Thu, Sep 12, 2024",
    desc:
      "Come study with e-board!",
    image: study,
  },
  {
    name: "Cohort Retreat",
    info: "Fri, Sep 13, 2024",
    desc:
      "",
    image: retreat,
  },
  {
    name: "GBM #1",
    info: "Tue, Sep 17, 2024",
    desc:
      "",
    image: gbm1,
  },
  {
    name: "Scavenger Hunt",
    info: "Wed, Sep 18, 2024",
    desc:
      "",
    image: scavenger2,
  },
  {
    name: "Study Social",
    info: "Thu, Sep 19, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Mentorship Speed Dating",
    info: "Fri, Sep 20, 2024",
    desc:
      "Come out and meet our amazing mentors!",
    image: speeddating,
  },
  {
    name: "GBM #2",
    info: "Tue, Sep 24, 2024",
    desc:
      "",
    image: gbm2,
  },
  {
    name: "Programming Event 1",
    info: "Wed, Sep 25, 2024",
    desc:
      "",
    image: prog1,
  },
  {
    name: "Study Social",
    info: "Thu, Sep 26, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Mocktail Mixer!",
    info: "Fri, Sep 27, 2024",
    desc:
      "",
    image: mixer,
  },
  {
    name: "GBM #3",
    info: "Tue, Oct 1, 2024",
    desc:
      "",
    image: gbm3,
  },
  {
    name: "Study Social",
    info: "Thu, Oct 3, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Bonfire Social",
    info: "Thu, Oct 3, 2024",
    desc:
      "",
    image: bonfire,
  },
  {
    name: "GBM #4",
    info: "Tue, Oct 8, 2024",
    desc:
      "",
    image: gbm4,
  },  
  {
    name: "Programming Event 2",
    info: "Wed, Oct 9, 2024",
    desc:
      "",
    image: prog2,
  },
  {
    name: "Study Social",
    info: "Thu, Oct 10, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Mentor Scavenger Hunt",
    info: "Sat, Oct 12, 2024",
    desc:
      "",
    image: scavenger1,
  },
  {
    name: "Mentor REveal!",
    info: "Sun, Oct 13, 2024",
    desc:
      "",
    image: reveal,
  },
  {
    name: "GBM #5",
    info: "Tue, Oct 15, 2024",
    desc:
      "",
    image: gbm5,
  },
  {
    name: "Study Social",
    info: "Thu, Oct 17, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Pumpkin Painting",
    info: "Fri, Oct 18, 2024",
    desc:
      "",
    image: pumpkin,
  },
  {
    name: "The Repurpose Project",
    info: "Sun, Oct 20, 2024",
    desc:
      "",
    image: repurpose,
  },
  {
    name: "GBM #6",
    info: "Tue, Oct 22, 2024",
    desc:
      "",
    image: gbm6,
  },
  {
    name: "Programming Event 3",
    info: "Wed, Oct 23, 2024",
    desc:
      "",
    image: prog3,
  },
  {
    name: "Study Social",
    info: "Thu, Oct 24, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "GBM #7",
    info: "Tue, Oct 29, 2024",
    desc:
      "",
    image: gbm7,
  },
  {
    name: "Game Night",
    info: "Fri, Nov 1, 2024",
    desc:
      "",
    image: game,
  },
  {
    name: "Project Downtown",
    info: "Sun, Nov 3, 2024",
    desc:
      "",
    image: downtown,
  },
  {
    name: "GBM #8",
    info: "Tue, Nov 5, 2024",
    desc:
      "",
    image: gbm8,
  },
  {
    name: "Study Social",
    info: "Thu, Nov 7, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "First-Gen Success Showcase",
    info: "Fri, Nov 8, 2024",
    desc:
      "",
    image: showcase,
  },
  {
    name: "Holiday Party!",
    info: "Sun, Nov 10, 2024",
    desc:
      "",
    image: winterformal,
  },
  {
    name: "GBM #9",
    info: "Tue, Nov 12, 2024",
    desc:
      "",
    image: gbm9,
  },
  {
    name: "Programming Event 4",
    info: "Wed, Nov 13, 2024",
    desc:
      "",
    image: prog4,
  },
  {
    name: "Study Social",
    info: "Wed, Nov 14, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Thanksgiving Potluck",
    info: "Sat, Nov 16, 2024",
    desc:
      "",
    image: thanksgiving,
  },
  {
    name: "GBM #10",
    info: "Tue, Nov 19, 2024",
    desc:
      "",
    image: gbm10,
  },
  {
    name: "Programming Event 5",
    info: "Wed, Nov 20, 2024",
    desc:
      "",
    image: prog5,
  },
  {
    name: "Study Social",
    info: "Thu, Nov 21, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "GBM #11",
    info: "Tue, Dec 3, 2024",
    desc:
      "",
    image: gbm11,
  },
  {
    name: "Study Social",
    info: "Thu, Dec 5, 2024",
    desc:
      "",
    image: study,
  },
  {
    name: "Holiday Extravaganza",
    info: "Sat, Dec 7, 2024",
    desc:
      "",
    image: holiday,
  },
  {
    name: "Study Social",
    info: "Thu, Dec 12, 2024",
    desc:
      "",
    image: study,
  },

];

export default events;